import { PlaceDetail } from '../components/PlaceDetail';
import { Loader } from '@/components/Loader';
import { MapProvider } from '@/components/Map';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { TablePagnination } from '@/components/ui/TableWrapper';
import { graphql } from '@/gql';
import { useTableControls } from '@/hooks/useTableControls';
import { useAppStore } from '@/stores';
import { OrganizationNotFoundError } from '@/utils';
import { Plus, SearchIcon } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useQuery } from 'urql';

const PlacesGql = graphql(`
  query PlacesGql(
    $organizationId: String!
    $first: Int!
    $offset: Int!
    $search: String
  ) {
    places(
      first: $first
      offset: $offset
      condition: { organizationId: $organizationId, search: $search }
      orderBy: [NAME_ASC]
    ) {
      totalCount
      nodes {
        id
        name
        description
        updatedAt
        createdAt
        radius
        center {
          longitude
          latitude
          srid
        }
        spatialData {
          geojson
          srid
        }
        encodedPolyline
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`);

const Places = () => {
  const [organizationId, isAdmin] = useAppStore((state) => [
    state.activeMembership?.id,
    state.activeMembership?.isAdmin,
  ]);

  const controls = useTableControls({ pageSize: 10 });

  if (!organizationId) {
    throw new OrganizationNotFoundError();
  }

  const [{ data, error, fetching }] = useQuery({
    query: PlacesGql,
    requestPolicy: 'cache-and-network',
    variables: {
      first: controls.pageSize,
      offset: controls.offset,
      organizationId,
      search: controls.search,
    },
  });

  const count = data?.places?.nodes.length ?? 0;

  return (
    <Card>
      <CardHeader>
        <CardTitle>Places</CardTitle>
        <CardDescription>
          Places allow your organization to geofence and name a particular area.
          These can be configured to identify and tag key locations for a Check
          In, Incident, or GuardMe.
        </CardDescription>
      </CardHeader>
      <CardContent className="space-y-4">
        <>
          <div className="flex justify-between items-center">
            <Link
              className="inline-flex"
              to="/settings/places/new"
            >
              <Button disabled={!isAdmin}>
                <Plus className="w-4 h-4 mr-1" />
                New Place
              </Button>
            </Link>

            <div className="relative">
              <SearchIcon className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
              <Input
                className="pl-8 sm:w-[300px] md:w-[200px] lg:w-[300px]"
                onChange={(event) => {
                  controls.setSearch(event.currentTarget.value);
                }}
                placeholder="Search Places..."
                type="search"
                value={controls.search ?? ''}
              />
            </div>
          </div>

          <MapProvider>
            <div className="my-4 py-4 border-y">
              <Loader
                isLoading={fetching}
                loadingError={error}
              >
                <div className="grid grid-cols-2 gap-4">
                  {data?.places?.nodes.map((item) => (
                    <PlaceDetail
                      center={item.center}
                      description={item.description}
                      encodedPolyline={item.encodedPolyline}
                      id={item.id}
                      key={`place_${item.id}`}
                      name={item.name}
                      radius={item.radius}
                      spatialData={item.spatialData}
                    />
                  ))}
                  {count === 0 && controls.search === null && (
                    <div className="col-span-full p-4 md:p-8 italic text-center">
                      No Places Defined
                    </div>
                  )}
                  {count === 0 && controls.search !== null && (
                    <div className="col-span-full p-4 md:p-8 italic text-center">
                      No Search Results Found
                    </div>
                  )}
                </div>
              </Loader>
            </div>
            <TablePagnination
              currentPage={controls.page}
              getSearchParameters={controls.getPageSearchParameters}
              pageSize={controls.pageSize}
              totalCount={data?.places?.totalCount}
            />
          </MapProvider>
        </>
      </CardContent>
    </Card>
  );
};

export { Places };
