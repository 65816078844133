import { type DateRange } from '@/components/DateRangePicker';
import { formatDatetime, fromLocalDate } from '@/lib/time';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

type TableControlDefaults = {
  pageSize?: number;
};

const DEFAULT_VALUES = {
  pageSize: 25,
};

const useTableControls = (defaults: TableControlDefaults = {}) => {
  const options = {
    ...DEFAULT_VALUES,
    ...defaults,
  };

  const [searchParameters, setSearchParameters] = useSearchParams();

  const search = searchParameters.get('search');

  const from = fromLocalDate(searchParameters.get('from'));
  const to = fromLocalDate(searchParameters.get('to'));
  const dateRange = {
    from: from ?? undefined,
    to: to ?? undefined,
  };
  const page = Number.parseInt(searchParameters.get('page') ?? '1', 10);
  const pageSize = Number.parseInt(
    searchParameters.get('pageSize') ?? options.pageSize.toString(),
    10,
  );
  const offset = (page - 1) * pageSize;

  const getPageSearchParameters = useMemo(
    () => (newPage: number) => {
      const current = new URLSearchParams(searchParameters);
      if (newPage === 1) {
        current.delete('page');
      } else {
        current.set('page', newPage.toString());
      }

      return current.toString();
    },
    [searchParameters],
  );

  const setSearch = useMemo(
    () => (searchInput: string) => {
      setSearchParameters((previous) => {
        if (searchInput) {
          previous.set('search', searchInput);
        } else {
          previous.delete('search');
        }

        previous.delete('page');
        return previous;
      });
    },
    [setSearchParameters],
  );

  const setDateRange = useMemo(
    () => (range?: DateRange) => {
      setSearchParameters((previous) => {
        previous.delete('from');
        previous.delete('to');

        if (range?.from) {
          previous.set(
            'from',
            formatDatetime({
              datetime: range.from,
              format: 'yyyy-MM-dd',
            }),
          );
        }

        if (range?.to) {
          previous.set(
            'to',
            formatDatetime({
              datetime: range.to,
              format: 'yyyy-MM-dd',
            }),
          );
        }

        return previous;
      });
    },
    [setSearchParameters],
  );

  return {
    dateRange,
    from,
    getPageSearchParameters,
    offset,
    page,
    pageSize,
    search,
    setDateRange,
    setSearch,
    to,
  };
};

export { useTableControls };
